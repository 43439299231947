@import (reference) '../../style/themes/index';

@form-prefix-cls: ~'@{ant-prefix}-form';
@form-item-prefix-cls: ~'@{form-prefix-cls}-item';

.@{form-prefix-cls}-horizontal {
  .@{form-item-prefix-cls}-label {
    flex-grow: 0;
  }
  .@{form-item-prefix-cls}-control {
    flex: 1 1 0;
    // https://github.com/ant-design/ant-design/issues/32777
    min-width: 0;
  }
}
